const environment = {
  host: "https://tide-thalassa8624.tidesoftware.be",
  builds: [
    {
      officeId: 1,
      agentId: 8,
      name: "Thalassa",
      key: "thalassa",
      path: "thalassa",
      url: "https://www.thalassacruises.be/",
      env: "production",
    },
  ],
};

module.exports = {
  environment,
};

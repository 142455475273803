// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-templates-detail-pages-blog-post-wildcard-jsx": () => import("./../../../src/templates/detailPages/blogPostWildcard.jsx" /* webpackChunkName: "component---src-templates-detail-pages-blog-post-wildcard-jsx" */),
  "component---src-templates-detail-pages-cruise-type-wildcard-jsx": () => import("./../../../src/templates/detailPages/cruiseTypeWildcard.jsx" /* webpackChunkName: "component---src-templates-detail-pages-cruise-type-wildcard-jsx" */),
  "component---src-templates-detail-pages-sailing-area-wildcard-jsx": () => import("./../../../src/templates/detailPages/sailingAreaWildcard.jsx" /* webpackChunkName: "component---src-templates-detail-pages-sailing-area-wildcard-jsx" */),
  "component---src-templates-detail-pages-ship-wildcard-jsx": () => import("./../../../src/templates/detailPages/shipWildcard.jsx" /* webpackChunkName: "component---src-templates-detail-pages-ship-wildcard-jsx" */),
  "component---src-templates-detail-pages-shipping-company-wildcard-jsx": () => import("./../../../src/templates/detailPages/shippingCompanyWildcard.jsx" /* webpackChunkName: "component---src-templates-detail-pages-shipping-company-wildcard-jsx" */),
  "component---src-templates-page-jsx": () => import("./../../../src/templates/page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */),
  "component---src-templates-product-jsx": () => import("./../../../src/templates/product.jsx" /* webpackChunkName: "component---src-templates-product-jsx" */),
  "component---src-templates-wildcard-page-jsx": () => import("./../../../src/templates/wildcardPage.jsx" /* webpackChunkName: "component---src-templates-wildcard-page-jsx" */)
}

